<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>底部菜单配置</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <el-form ref="form" size="medium" :label-width="this.env.label_width">
                <el-row v-for="(item,index) in bottommenu" :key="index">
                    <el-form-item :label="'底部菜单'+(index+1)">
                        <el-col :span="8">
                            <el-input v-model="item.name" maxlength="4" placeholder="请输入名称"></el-input>
                        </el-col>
                        <el-col :span="8">
                            <el-select v-model="item.type" placeholder="请选择类型" style="width: 100%">
                                <el-option label="菜单" value=""></el-option>
                                <el-option label="点击事件" value="click"></el-option>
                                <el-option label="网页" value="view"></el-option>
                                <el-option label="小程序页面" value="miniprogram"></el-option>
                                <!--                                <el-option label="扫码" value="scancode_push"></el-option>-->
                                <!--                                <el-option label="拍照" value="pic_photo_or_album"></el-option>-->
                                <el-option label="图片素材" value="media_id"></el-option>
                            </el-select>
                        </el-col>
                        <el-col v-show="item.type ==='click'" :span="8">
                            <div>
                                <el-input v-model="item.key" placeholder="请输入key"></el-input>
                            </div>
                        </el-col>
                        <el-col v-show="item.type ==='view'" :span="8">
                            <div>
                                <el-input v-model="item.url" placeholder="请输入url"></el-input>
                            </div>
                        </el-col>
                        <el-col v-show="item.type ==='media_id'" :span="8">
                            <div>
                                <el-input v-model="item.media_id" placeholder="请输入图片素材media_id"></el-input>
                            </div>
                        </el-col>
                        <el-col v-show="item.type ==='miniprogram'" :span="8">
                            <div>
                                <el-input v-model="item.appid" placeholder="请输入小程序appid"></el-input>
                            </div>
                            <div>
                                <el-input v-model="item.pagepath" placeholder="请输入小程序页面"></el-input>
                            </div>
                            <div>
                                <el-input v-model="item.url" placeholder="请输入备用url"></el-input>
                            </div>
                        </el-col>
                    </el-form-item>

                    <!--二级菜单配置-->
                    <div v-show="is_empty(item.type)">
                        <el-row v-for="(item2,index2) in item.sub_button" :key="index2">
                            <el-form-item :label="'菜单'+(index+1)+'_'+(index2+1)">
                                <el-col :span="8">
                                    <el-input v-model="item2.name" maxlength="8" placeholder="请输入名称"></el-input>
                                </el-col>
                                <el-col :span="8">
                                    <el-select v-model="item2.type" placeholder="请选择事件" style="width: 100%">
                                        <el-option label="点击事件" value="click"></el-option>
                                        <el-option label="网页" value="view"></el-option>
                                        <el-option label="小程序页面" value="miniprogram"></el-option>
                                        <!--                                    <el-option label="扫码" value="scancode_push"></el-option>-->
                                        <!--                                    <el-option label="拍照" value="pic_photo_or_album"></el-option>-->
                                        <el-option label="图片素材" value="media_id"></el-option>
                                    </el-select>
                                </el-col>
                                <el-col v-show="item2.type ==='click'" :span="8">
                                    <div>
                                        <el-input v-model="item2.key" placeholder="请输入key"></el-input>
                                    </div>
                                </el-col>
                                <el-col v-show="item2.type ==='view'" :span="8">
                                    <div>
                                        <el-input v-model="item2.url" placeholder="请输入url"></el-input>
                                    </div>
                                </el-col>
                                <el-col v-show="item2.type ==='media_id'" :span="8">
                                    <div>
                                        <el-input v-model="item2.media_id" placeholder="请输入图片素材media_id"></el-input>
                                    </div>
                                </el-col>
                                <el-col v-show="item2.type ==='miniprogram'" :span="8">
                                    <div>
                                        <el-input v-model="item2.appid" placeholder="请输入小程序appid"></el-input>
                                    </div>
                                    <div>
                                        <el-input v-model="item2.pagepath" placeholder="请输入小程序页面"></el-input>
                                    </div>
                                    <div>
                                        <el-input v-model="item2.url" placeholder="请输入备用页面url"></el-input>
                                    </div>
                                </el-col>
                            </el-form-item>

                        </el-row>
                    </div>
                </el-row>
                <el-form-item>
                    <el-row>
                        <el-col :span="12">
                            <el-button size="primary" type="primary" @click="save">保存并同步</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>

export default {
    components: {},
    data() {
        return {
            loading: true,      // 加载状态
            page_name: '底部菜单配置',
            // 底部菜单
            bottommenu: [
                {
                    name: '',
                    type: 'click',
                    sub_button: [
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                    ]
                },
                {
                    name: '', type: 'click', sub_button: [
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                    ]
                },
                {
                    name: '', type: 'click', sub_button: [
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                        {name: '', type: 'click'},
                    ]
                },
            ],
        }
    },
    // 创建
    created() {
        this.getinfo();
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取数据
        getinfo() {
            let postdata = {
                api_name: "wxmp.bottommenu.getset",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.bottommenu = json.data.bottommenu
                }
            })
        },
        // 保存
        save() {
            let bottommenu = this.bottommenu

            // console.log('bottommenu', bottommenu)

            let postdata = {
                api_name: "wxmp.bottommenu.saveset",
                token: this.Tool.get_l_cache('token'),
                bottommenu,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
    border: 1px solid #40a9ff;
}

.box-content {
    display: flex;
    padding: 10px;
}

.box-content-border {
    padding: 10px;
}

.content-title {
    width: 20%;
    padding-right: 20px;
    text-align: center;
}
</style>
